import React from "react"
import SEO from "../components/seo"
import Hero from "../components/Home_Page/Hero/Hero"
import Products from "../components/Home_Page/Products/Products"
import Features from "../components/Home_Page/Features/Features"
import Services from "../components/Home_Page/Services/Services"
import Banner from "../components/Banner/Banner"
import Layout from "../components/layout"
const IndexPage = ({ data }) => {
  const banner = data.allStrapiBanner.edges[0].node
  return (
    <Layout>
      <SEO title="CloudGate Consulting" />
      <Hero />
      <Products />
      <Features />
      <Services />
      <Banner bannerText={banner.Text} anchorText={banner.Call_To_Action} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiBanner {
      edges {
        node {
          Text
          Call_To_Action
        }
      }
    }
  }
`

export default IndexPage
