import React from "react"
import "./ProductTile.scss"
import PropTypes from "prop-types"
import { Link } from "gatsby"

export default function ProductTile({
  title,
  description,
  icon,
  destination,
  moreInfoText,
}) {
  return (
    <div className="product-tile">
      <Link to={`/products/${destination}`}>
        <div className="product-tile__container">
          <div className="product-tile__container-header is-flex">
            <div className="product-tile__container-icon is-flex">
              <img src={icon} alt={title} />
            </div>
            <h1 className="product-tile__container-title">{title}</h1>
          </div>
          <div className="product-tile__container-description">
            {description}
          </div>
          <div className="product-tile__container-learn-more">
            {moreInfoText}
          </div>
        </div>
      </Link>
    </div>
  )
}

ProductTile.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  moreInfoText: PropTypes.node.isRequired,
  destination: PropTypes.string.isRequired,
}
