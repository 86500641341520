import React from "react"
import "./Banner.scss"
import PropTypes from "prop-types"
export default function Banner({
  bannerText,
  anchorText,
  anchorDestination = "page2",
}) {
  return (
    <section className="banner section">
      <div className="banner__container">
        <div className="container">
          <div className="banner__text">
            <h1 className="title has-text-centered">{bannerText}</h1>
          </div>

          {/* <div className="banner__button  has-text-centered">
            <PrimaryAnchor to={`/${anchorDestination}`}>
              {anchorText}
            </PrimaryAnchor>
          </div> */}
        </div>
      </div>
    </section>
  )
}

Banner.propTypes = {
  bannerText: PropTypes.string.isRequired,
  anchorText: PropTypes.string.isRequired,
  anchorDestination: PropTypes.string,
}
