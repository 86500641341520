import React from "react"
import BaseAnchor from "../BaseAnchor/BaseAnchor"

export default function PrimaryAnchor({ children, to }) {
  return (
    <BaseAnchor
      to={to}
      style={{
        backgroundColor: "#CB6E17",
        color: "#FFFFFF",
      }}
    >
      {children}
    </BaseAnchor>
  )
}
