import React from "react"
import "./Services.scss"
import Service from "../../Service/Service"
import { useStaticQuery, graphql } from "gatsby"

export default function Services() {
  const servicesQuery = useStaticQuery(graphql`
    query {
      allStrapiService {
        edges {
          node {
            Name
            Description
            Service_Icon {
              publicURL
            }
          }
        }
      }

      allStrapiServicesection {
        edges {
          node {
            Title
            Description
          }
        }
      }
    }
  `)
  const services = servicesQuery.allStrapiService.edges
  const servicesInformation =
    servicesQuery.allStrapiServicesection.edges[0].node
  return (
    <section id="services" className="home-services section">
      <div className="home-services__container">
        <div className="container">
          <div className="home-services__container-title">
            <h1 className="title has-text-centered">
              {servicesInformation.Title}
            </h1>
            <h2 className="subtitle has-text-centered">
              {servicesInformation.Description}
            </h2>
          </div>
          <div className="columns home-services__container-tiles-container">
            {services.map((service, index) => (
              <div className="column" key={index}>
                <Service
                  title={service.node.Name}
                  description={service.node.Description}
                  icon={service.node.Service_Icon.publicURL}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
