import React from "react"
import "./Features.scss"
import Feature from "../../Feature/Feature"
import { useStaticQuery } from "gatsby"

export default function Features() {
  const featuresQuery = useStaticQuery(graphql`
    query {
      allStrapiPlatformfeature {
        edges {
          node {
            Name
            Description
            Feature_Type
            Platform_Feature_Icon {
              publicURL
            }
          }
        }
      }
      allStrapiPlatformsection {
        edges {
          node {
            Title
            Infrastructure_Features_Title
            Functional_Features_Title
          }
        }
      }
    }
  `)

  const features = featuresQuery.allStrapiPlatformfeature.edges
  const functionalFeatures = features.filter(
    feature => feature.node.Feature_Type === "Functional"
  )
  const infrastructureFeatures = features.filter(
    feature => feature.node.Feature_Type === "Infrastructure"
  )
  const platformInformation =
    featuresQuery.allStrapiPlatformsection.edges[0].node

  return (
    <section id="features" className="home-features section">
      <div className="home-features__container">
        <div className="container">
          <div className="home-features__container-title">
            <h1 className="title has-text-centered">
              {platformInformation.Title}
            </h1>
          </div>

          <div className="home-features__container-columns-infrastructure">
            <h2 className="subtitle has-text-centered">
              {platformInformation.Infrastructure_Features_Title}
            </h2>
            <div className="columns is-multiline home-features__container-columns ">
              {infrastructureFeatures.map((feature, index) => (
                <div
                  key={index}
                  className="column is-one-third-fullhd is-half-tablet is-full-mobile"
                >
                  <Feature
                    title={feature.node.Name}
                    description={feature.node.Description}
                    icon={feature.node.Platform_Feature_Icon.publicURL}
                  ></Feature>
                </div>
              ))}
            </div>
          </div>

          <div className="home-features__container-columns">
            <div className="home-features__container-columns-functional">
              <h2 className="subtitle has-text-centered">
                {platformInformation.Functional_Features_Title}
              </h2>
              <div className="columns is-multiline home-features__container-columns ">
                {functionalFeatures.map((feature, index) => (
                  <div
                    key={index}
                    className="column is-one-third-fullhd is-half-tablet is-full-mobile"
                  >
                    <Feature
                      title={feature.node.Name}
                      description={feature.node.Description}
                      icon={feature.node.Platform_Feature_Icon.publicURL}
                    ></Feature>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* <div className="home-features__container-columns columns is-multiline">
            <div className="column is-6">
              <h2 className="subtitle has-text-centered">
                {platformInformation.Infrastructure_Features_Title}
              </h2>
              {functionalFeatures.map((feature, index) => (
                <div key={index} className="column">
                  <Feature
                    title={feature.node.Name}
                    description={feature.node.Description}
                    icon={feature.node.Platform_Feature_Icon.publicURL}
                  ></Feature>
                </div>
              ))}
            </div>
            <div className="column is-6">
              <div className="subtitle has-text-centered">
                {platformInformation.Functional_Features_Title}
              </div>
              {infrastructureFeatures.map((feature, index) => (
                <div key={index} className="column">
                  <Feature
                    title={feature.node.Name}
                    description={feature.node.Description}
                    icon={feature.node.Platform_Feature_Icon.publicURL}
                  ></Feature>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}
