import React from "react"
import "./Feature.scss"
import PropTypes from "prop-types"
export default function Feature({ title, description, icon }) {
  return (
    <div className="feature-tile ">
      <div className="feature-tile__container is-flex">
        <div className="feature-tile__container-icon">
          <img src={icon} alt={title} />
        </div>
        <div className="feature-tile__container-data">
          <h1 className="feature-tile__container-title">{title}</h1>
          <div className="feature-tile__container-description">
            {description}
          </div>
        </div>
      </div>
    </div>
  )
}

Feature.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
