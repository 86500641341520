import React from "react"
import "./Service.scss"
import PropTypes from "prop-types"

export default function Service({ icon, title, description }) {
  return (
    <div className="service">
      <div className="service__container">
        <div className="service__container-icon has-text-centered">
          <img src={icon} alt={title} />
        </div>
        <div className="service__container-title has-text-centered">
          {title}
        </div>
        <div className="service__container-description has-text-centered">
          {description}
        </div>
      </div>
    </div>
  )
}

Service.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}
