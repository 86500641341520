import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
export default function BaseAnchor({ children, to, style }) {
  return (
    <Link to={to} className="base-button" style={style}>
      {children}
    </Link>
  )
}

BaseAnchor.propTypes = {
  children: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
}
