import React from "react"
import "./Hero.scss"
import PrimaryAnchor from "../../PrimaryAnchor/PrimaryAnchor"
import { useStaticQuery } from "gatsby"

export default function Hero() {
  const heroQuery = useStaticQuery(graphql`
    query {
      allStrapiHero {
        edges {
          node {
            Title
            Description
            Hero_Image {
              id
              publicURL
            }
            Call_To_Action_1
          }
        }
      }
    }
  `)

  const hero = heroQuery.allStrapiHero.edges[0].node
  return (
    <section
      id="#hero"
      className="hero top-hero"
      style={{ backgroundImage: `url(${hero.Hero_Image.publicURL})` }}
    >
      <div className="hero-body is-flex">
        <div className="container">
          <div className="columns">
            <div className="hero-wrapper column is-half-fullhd is-three-quarters-tablet is-full-mobile has-text-centered-mobile">
              <h1 className="title">{hero.Title}</h1>
              <h2 className="subtitle">{hero.Description}</h2>
              <div className="call-to-action-anchors is-flex">
                <PrimaryAnchor to="/contact">
                  {hero.Call_To_Action_1}
                </PrimaryAnchor>
                {/* <SechondaryAnchor to="/">
                  {hero.Call_To_Action_2}
                </SechondaryAnchor> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
