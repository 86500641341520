import React from "react"
import "./Products.scss"
import ProductTile from "../../ProductTile/ProductTile"
import PrimaryAnchor from "../../PrimaryAnchor/PrimaryAnchor"
import { useStaticQuery, graphql } from "gatsby"

export default function Products() {
  const productsQuery = useStaticQuery(graphql`
    query {
      allStrapiProduct {
        edges {
          node {
            Name
            Description
            Product_Icon {
              publicURL
            }
            Slug
          }
        }
      }
      allStrapiProductsection {
        edges {
          node {
            Title
            Description
            Platform_Title
            Platform_Description
            Product_Section_Image {
              publicURL
            }
            Read_More_Text
          }
        }
      }
    }
  `)

  const products = productsQuery.allStrapiProduct.edges
  const productsInformation =
    productsQuery.allStrapiProductsection.edges[0].node

  return (
    <section id="products" className="home-products section">
      <div className="home-products__container">
        <div className="container">
          <div className="home-products__container-title">
            <h1 className="title has-text-centered">
              {productsInformation.Title}
            </h1>
            <h2 className="subtitle has-text-centered">
              {productsInformation.Description}
            </h2>
          </div>
          <div className="home-products__container-screenshot">
            <img
              src={productsInformation.Product_Section_Image.publicURL}
              alt={productsInformation.Title}
            />
          </div>
          <div className="columns is-multiline home-products__container-tiles-container">
            {products.map((product, index) => (
              <div
                key={index}
                className="column is-one-third-desktop is-half-tablet is-full-mobile"
              >
                <ProductTile
                  title={product.node.Name}
                  description={product.node.Description}
                  icon={product.node.Product_Icon.publicURL}
                  moreInfoText={productsInformation.Read_More_Text}
                  destination={product.node.Slug}
                />
              </div>
            ))}
          </div>

          <div className="home-platform-container">
            <h1 className="title has-text-centered">
              {productsInformation.Platform_Title}
            </h1>
            <h2 className="subtitle has-text-centered">
              {productsInformation.Platform_Description}
            </h2>
            <div className="more-info-button-container has-text-centered">
              <PrimaryAnchor to="/">Learn More</PrimaryAnchor>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
